import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Text,
  AuthCode,
  AuthCodeRef,
  Logo,
  Title,
  UnsupportedPhoneNumberToastError,
} from "@tigris/mesokit";
import { useNavigate } from "react-router-dom";
import { AccountAppRoutes } from "../utils/constants";
import { resolveSend2FACode, resolveUser, resolveVerify2FA } from "../api";

import { loginAnimation } from "../utils/animation";
import { useAppContext } from "../hooks/useAppContext";
import { toast } from "sonner";
import { TwoFactorMethod } from "../generated/sdk";
import { ErrorMessages } from "@utils/errorMessages";
import { Posthog, TelemetryEvents } from "@tigris/common";

const TOAST_ID = "Login2FA";

export const Login2FA = ({ resendSeconds }: { resendSeconds?: number }) => {
  const authCodeRef = useRef<AuthCodeRef>(null);
  const navigate = useNavigate();
  const { updateUser } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);

  const verifyAuthCode = useCallback(
    async (authCode: string) => {
      setIsLoading(true);

      const verify2FAResult = await resolveVerify2FA({
        input: { code: authCode },
      });

      if (verify2FAResult.isErr()) {
        toast.error(verify2FAResult.error, { id: TOAST_ID });
        setIsLoading(false);

        authCodeRef.current?.clear();
        setTimeout(() => authCodeRef.current?.focus(), 300);

        return;
      }

      const userResult = await resolveUser();

      if (userResult.isOk()) {
        updateUser(userResult.value);
        navigate(
          userResult.value.status === "active"
            ? AccountAppRoutes.TRANSFERS
            : AccountAppRoutes.PROFILE_SETTINGS,
        );
        return;
      }

      toast.error(userResult.error, { id: TOAST_ID });
    },
    [navigate, updateUser],
  );

  useEffect(() => {
    setTimeout(() => {
      authCodeRef.current?.focus();
    }, 340);
  }, []);

  const resendCode = useCallback(async () => {
    setIsLoading(true);

    const send2FACodeResult = await resolveSend2FACode({
      input: { method: TwoFactorMethod.PHONE },
    });

    if (send2FACodeResult.isErr()) {
      if (
        send2FACodeResult.error ===
        ErrorMessages.twoFactorAuth.UNSUPPORTED_PHONE_NUMBER_ERROR
      ) {
        toast.error(<UnsupportedPhoneNumberToastError />, { id: TOAST_ID });
        Posthog.capture(TelemetryEvents.onboardingUnsupportedPhoneNumber);
        return;
      }
      toast.error(send2FACodeResult.error, { id: TOAST_ID });
      return;
    }

    setIsLoading(false);
    setTimeout(() => authCodeRef.current?.focus());
  }, []);

  const onAuthCodeChange = useMemo(
    () => async (authCode: string) => {
      if (authCode.length === 6) {
        await verifyAuthCode(authCode);
      }
    },
    [verifyAuthCode],
  );

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key="otp-code"
        className="mx-auto flex max-w-[324px] flex-col items-center justify-center gap-2 md:max-w-none"
        {...loginAnimation}
      >
        <section className="mb-4 flex flex-col items-center gap-1 text-center">
          <Logo showText={false} size="md" className="mb-2" />
          <Title.Medium className="font-bold">
            Two-Factor Authentication
          </Title.Medium>
          <Text>We sent an authentication code to your phone.</Text>
        </section>
        <AuthCode
          containerClassName="md:px-8"
          authCodeClassName="text-black"
          disabled={isLoading}
          onAuthCodeChange={onAuthCodeChange}
          ref={authCodeRef}
          resendSeconds={resendSeconds}
          onResend={resendCode}
        />
      </motion.div>
    </AnimatePresence>
  );
};
