import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  ReactElement,
} from "react";
import { toast } from "sonner";
import { AccountAppRoutes } from "../../utils/constants";
import {
  AuthCode,
  AuthCodeRef,
  Button,
  Logo,
  Text,
  Title,
  UnsupportedPhoneNumberToastError,
} from "@tigris/mesokit";
import {
  resolveVerify2FA,
  resolveUser,
  resolveSend2FACode,
  resolvePerformMagicLinkLogin,
} from "../../api";
import { TwoFactorMethod } from "../../generated/sdk";
import { EnterPassword } from "./EnterPassword";
import { parseResetPasswordParams } from "../../parseParams";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, Variants, motion } from "framer-motion";
import { MIN_PASSWORD_LENGTH, Posthog, TelemetryEvents } from "@tigris/common";
import { useAppContext } from "../../hooks/useAppContext";
import { ErrorMessages } from "@utils/errorMessages";

const TOAST_ID = "ResetPassword";

type Screens =
  | "initializing"
  | "error"
  | "verify2fa"
  | "enterPassword"
  | "updated";

const initializingContentAnimationVariants: Variants = {
  initial: { opacity: 0, scale: 0.8 },
  animate: { opacity: 0.5, scale: 1 },
  exit: { opacity: 0, scale: 0.8 },
};

const contentAnimationVariants: Variants = {
  initial: { opacity: 0, scale: 0.8 },
  animate: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.8 },
};

export const ResetPassword = ({
  resendSeconds,
}: {
  resendSeconds?: number;
}) => {
  const navigate = useNavigate();
  const { session, logout } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [activeScreen, setActiveScreen] = useState<Screens>("initializing");
  const authCodeRef = useRef<AuthCodeRef>(null);
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [hasOnboardedPhoneNumber, setHasOnboardedPhoneNumber] =
    useState<boolean>();

  useEffect(() => {
    if (!session || !navigate) return;

    (async () => {
      const params = parseResetPasswordParams();
      if (params.isErr()) {
        setActiveScreen("error");
        setIsLoading(false);
        return;
      }

      setEmailAddress(params.value.email);

      const loginResult = await resolvePerformMagicLinkLogin({
        input: {
          code: params.value.code,
          email: params.value.email,
          riskSessionKey: session!.riskSessionKey,
        },
      });

      if (loginResult.isErr()) {
        setActiveScreen("error");
        setIsLoading(false);
        return;
      }

      setIsLoading(false);

      if (loginResult.value.profileStatus.phone) {
        setActiveScreen("verify2fa");
        setHasOnboardedPhoneNumber(true);
      } else {
        setActiveScreen("enterPassword");
        setHasOnboardedPhoneNumber(false);
      }

      setTimeout(() => authCodeRef.current?.focus(), 900);
    })();
  }, [navigate, session]);

  const verifyAuthCode = useCallback(async (authCode: string) => {
    setIsLoading(true);

    const verify2FAResult = await resolveVerify2FA({
      input: { code: authCode },
    });

    if (verify2FAResult.isErr()) {
      toast.error(verify2FAResult.error, { id: TOAST_ID });
      setIsLoading(false);

      authCodeRef.current?.clear();
      setTimeout(() => authCodeRef.current?.focus(), 300);

      return;
    }

    const userResult = await resolveUser();

    if (userResult.isOk()) {
      setIsLoading(false);
      setActiveScreen("enterPassword");
      return;
    }

    toast.error(userResult.error, { id: TOAST_ID });
  }, []);

  const resendCode = useCallback(async () => {
    setIsLoading(true);

    const send2FACodeResult = await resolveSend2FACode({
      input: { method: TwoFactorMethod.PHONE },
    });

    if (send2FACodeResult.isErr()) {
      if (
        send2FACodeResult.error ===
        ErrorMessages.twoFactorAuth.UNSUPPORTED_PHONE_NUMBER_ERROR
      ) {
        toast.error(<UnsupportedPhoneNumberToastError />, { id: TOAST_ID });
        Posthog.capture(TelemetryEvents.onboardingUnsupportedPhoneNumber);
        return;
      }
      toast.error(send2FACodeResult.error, { id: TOAST_ID });
      return;
    }

    setIsLoading(false);
    setTimeout(() => authCodeRef.current?.focus());
  }, []);

  const onAuthCodeChange = useMemo(
    () => async (authCode: string) => {
      if (authCode.length === 6) {
        await verifyAuthCode(authCode);
      }
    },
    [verifyAuthCode],
  );

  const handleEnterPasswordError = useCallback<(message: string) => void>(
    (message) => {
      toast.error(message, { id: TOAST_ID });
    },
    [],
  );

  const handleEnterPasswordSuccess = useCallback(() => {
    setIsLoading(false);
    setActiveScreen("updated");
  }, []);

  const templates = useMemo<
    Record<
      Screens,
      {
        title: string;
        body?: ReactElement | string;
        component: ReactElement;
      }
    >
  >(() => {
    return {
      initializing: { title: "", body: "", component: <></> },
      error: {
        title: "Something went wrong.",
        body: (
          <>
            Your password cannot be updated at this time. Please contact support
            at{" "}
            <a
              className="font-bold opacity-80 transition-opacity duration-100 hover:opacity-100"
              href="mailto:support@meso.network"
            >
              support@meso.network
            </a>
          </>
        ),
        component: <></>,
      },
      verify2fa: {
        title: "Confirm Your Identity",
        component: (
          <div data-testid="verify2fa">
            <AuthCode
              authCodeClassName="text-black"
              disabled={isLoading}
              onAuthCodeChange={onAuthCodeChange}
              ref={authCodeRef}
              onResend={resendCode}
              resendSeconds={resendSeconds}
            />
          </div>
        ),
      },
      enterPassword: {
        title: "Enter A New Password",
        body: `Your password needs to be at least ${MIN_PASSWORD_LENGTH} characters, but longer is better.`,
        component: (
          <div data-testid="enterPassword">
            <EnterPassword
              onError={handleEnterPasswordError}
              onSuccess={handleEnterPasswordSuccess}
              emailAddress={emailAddress}
            />
          </div>
        ),
      },
      updated: {
        title: "Password Updated",
        body: `${hasOnboardedPhoneNumber ? "You can close this window and return to your transfer or log in to your Meso account." : "You can close this window and return to the app where you started signing up for Meso."}`,
        component: (
          <div
            data-testid="updatedPassword"
            className="mx-auto flex w-[306px] flex-col gap-4"
          >
            {hasOnboardedPhoneNumber && (
              <Button
                data-testid="navigateToLogin"
                onClick={() => {
                  logout();
                  navigate(AccountAppRoutes.ROOT);
                }}
              >
                My Meso Account
              </Button>
            )}
          </div>
        ),
      },
    };
  }, [
    emailAddress,
    handleEnterPasswordError,
    handleEnterPasswordSuccess,
    isLoading,
    logout,
    navigate,
    onAuthCodeChange,
    resendCode,
    hasOnboardedPhoneNumber,
    resendSeconds,
  ]);

  return (
    <AnimatePresence mode="wait">
      {activeScreen === "initializing" ? (
        <motion.div
          data-testid="loader"
          key="loader"
          initial="initial"
          animate="animate"
          exit="exit"
          variants={initializingContentAnimationVariants}
          className="flex flex-col items-center justify-center gap-4"
        >
          <Logo showText={false} size="md" className="mb-2" />
          <Text className="animate-pulse text-sm font-bold">Loading...</Text>
        </motion.div>
      ) : (
        <motion.div
          data-testid="content"
          key="content"
          initial="initial"
          animate="animate"
          exit="exit"
          variants={contentAnimationVariants}
          className="mx-auto flex max-w-[324px] flex-col items-center justify-center gap-4 md:max-w-none"
        >
          <section className="flex flex-col items-center gap-1">
            <Logo showText={false} size="md" className="mb-2" />
            <Title.Medium data-testid="content:title" className="font-bold">
              {templates[activeScreen].title}
            </Title.Medium>
            <Text
              data-testid={`content:body:${activeScreen}`}
              className="text-center"
            >
              {templates[activeScreen].body}
            </Text>
          </section>
          <div className="w-full">{templates[activeScreen].component}</div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
