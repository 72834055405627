import {
  PartnerLogo,
  Text,
  WalletInstrument,
  formatCurrency,
} from "@tigris/mesokit";
import {
  FiatInstrumentFragment,
  TransferFragment,
  TransferKind,
  TransferStatus,
  WalletInstrumentFragment,
} from "../generated/sdk";
import { TransferDetail } from "./TransferDetail";
import { TransferKindBadge } from "./TransferKindBadge";
import { AnimatePresence, motion } from "framer-motion";
import { Dialog, DialogPanel } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatRelative } from "date-fns";
import {
  faExclamationCircle,
  faClose,
  faLoader,
} from "@fortawesome/pro-solid-svg-icons";
import { useAppContext } from "../hooks/useAppContext";
import { useMemo, useState } from "react";
import {
  cryptoAssetFromExchangeRate,
  truncateAmount,
  networkFromExchangeRate,
} from "@tigris/common";

const TransferStatusBadge = ({ status }: { status: TransferStatus }) => {
  switch (status) {
    case TransferStatus.EXECUTING:
      return (
        <div className="ml-2 flex items-center gap-1">
          <FontAwesomeIcon icon={faLoader} size="lg" className="fa-spin" />
          <span className="text-sm font-semibold">In Progress...</span>
        </div>
      );
    case TransferStatus.DECLINED:
      return (
        <div className="ml-2 flex items-center gap-1 text-[#EC4848]">
          <FontAwesomeIcon
            icon={faExclamationCircle}
            size="lg"
            className="fa-solid"
          />
          <span className="text-sm font-semibold">Transfer Failed</span>
        </div>
      );
    default:
      return;
  }
};

export const TransferItem = ({ transfer }: { transfer: TransferFragment }) => {
  const { session } = useAppContext();
  const [showDetail, setShowDetail] = useState(false);
  const asset = cryptoAssetFromExchangeRate(transfer.exchangeRate);
  const transferKind = transfer.kind;
  const isCashIn = transferKind === TransferKind.CASH_IN;

  var fiatInstrument: FiatInstrumentFragment | undefined;
  var walletInstrument: WalletInstrumentFragment | undefined;
  if (isCashIn) {
    fiatInstrument =
      transfer.sourceInstrument &&
      transfer.sourceInstrument.__typename === "FiatInstrument"
        ? transfer.sourceInstrument
        : undefined;
    walletInstrument =
      transfer.destInstrument &&
      transfer.destInstrument.__typename === "WalletInstrument"
        ? transfer.destInstrument
        : undefined;
  } else {
    walletInstrument =
      transfer.sourceInstrument &&
      transfer.sourceInstrument.__typename === "WalletInstrument"
        ? transfer.sourceInstrument
        : undefined;
    fiatInstrument =
      transfer.destInstrument &&
      transfer.destInstrument.__typename === "FiatInstrument"
        ? transfer.destInstrument
        : undefined;
  }

  const depositAddressInstrument = useMemo(
    () =>
      !isCashIn &&
      session?.user?.depositAddressInstruments?.__typename ===
        "DepositAddressInstruments"
        ? session.user.depositAddressInstruments.collection.find(
            (dai) => dai.id === transfer.sourceInstrumentId,
          )
        : undefined,
    [
      isCashIn,
      session?.user?.depositAddressInstruments,
      transfer.sourceInstrumentId,
    ],
  );

  const network = networkFromExchangeRate(transfer.exchangeRate);

  return (
    <>
      <div
        key={transfer.id}
        className="-mx-4 flex cursor-pointer items-center justify-between rounded-2xl p-4 text-neutral-800 transition-colors hover:bg-neutral-100 dark:text-white hover:dark:bg-neutral-800"
        onClick={() => setShowDetail(!showDetail)}
      >
        <div className="flex items-center gap-2">
          <PartnerLogo
            size="md"
            displayName={transfer.partner.displayName}
            logoUri={transfer.partner.logoUri}
          />
          <div className="flex flex-col">
            <div className="flex items-center">
              <Text className="text-sm font-semibold">
                {transfer.partner.displayName}
              </Text>
              <TransferStatusBadge status={transfer.status} />
            </div>
            <div className="flex gap-2 text-xs font-semibold capitalize opacity-60">
              {formatRelative(new Date(transfer.createdAt), Date.now()).replace(
                / at/,
                "",
              )}
              <WalletInstrument
                network={network}
                walletAddress={walletInstrument?.address ?? ""}
              />
            </div>
          </div>
        </div>

        <div className="flex gap-4">
          <TransferKindBadge kind={transfer.kind} />
          {transfer.kind === TransferKind.CASH_IN && (
            <div className="flex w-min flex-col text-right">
              <Text className="font-semibold">
                +{truncateAmount(asset, transfer.destination.amount)}{" "}
                {transfer.destination.currency}
              </Text>
              <Text className="text-sm font-semibold opacity-60">
                {formatCurrency(
                  transfer.sourceTotalPresented.amount,
                  transfer.sourceTotalPresented.currency,
                )}
              </Text>
            </div>
          )}
          {transfer.kind === TransferKind.CASH_OUT && (
            <div className="flex w-min flex-col text-right">
              <span className="font-semibold">
                -{truncateAmount(asset, transfer.sourceTotal.amount)}{" "}
                {transfer.sourceTotal.currency}
              </span>
              <span className="text-sm font-semibold">
                {formatCurrency(
                  transfer.destination.amount,
                  transfer.destination.currency,
                )}
              </span>
            </div>
          )}
        </div>
      </div>

      <AnimatePresence>
        <Dialog
          as={motion.div}
          className="relative z-30"
          open={showDetail}
          onClose={() => setShowDetail(false)}
        >
          <div
            className="fixed inset-0 z-40 bg-white/60 dark:bg-neutral-950/60"
            aria-hidden="true"
          />
          <div className="fixed inset-0 z-50 flex h-full w-screen justify-center overflow-auto sm:pt-24">
            <div className="fixed top-2 right-2 z-10 flex h-8 w-8 items-center justify-center rounded-full border border-neutral-100/60 bg-neutral-100/40 text-neutral-700 sm:hidden">
              <FontAwesomeIcon icon={faClose} />
            </div>
            <DialogPanel data-testid="transfer-detail-dialog">
              <TransferDetail
                transfer={transfer}
                fiatInstrument={fiatInstrument}
                walletInstrument={walletInstrument}
                depositAddressInstrument={depositAddressInstrument}
              />
            </DialogPanel>
          </div>
        </Dialog>
      </AnimatePresence>
    </>
  );
};
